<div tuiGroup class="switcher" [collapsed]="true">
  <button
    tuiIconButton
    type="button"
    class="button button_left"
    icon="tuiIconMenu"
    [appearance]="display === 'list' ? 'primary' : 'outline'"
    size="xs"
    (click)="displayChange.emit('list')"
  ></button>
  <button
    tuiIconButton
    type="button"
    class="button button_right"
    icon="tuiIconGrid"
    [appearance]="display === 'grid' ? 'primary' : 'outline'"
    size="xs"
    (click)="displayChange.emit('grid')"
  ></button>
</div>
