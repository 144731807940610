/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EditCommitteeContact } from './editCommitteeContact';


/**
 * Данные для редактирования комитета.
 */
export interface EditCommittee { 
    /**
     * Наименование.
     */
    name?: string;
    /**
     * Описание.
     */
    description?: string;
    /**
     * Владелец.
     */
    ownedBy?: string;
    /**
     * ID сотрудника.
     */
    secretaryId?: string;
    /**
     * ID сотрудника.
     */
    managerId?: string;
    /**
     * ID родительского комитета.
     */
    parentCommitteeId?: string;
    /**
     * ID родительского комитета.
     */
    parentId?: string;
    /**
     * Признак могут ли другие участники вступать в комитет.
     */
    isNewMembersAllowed?: boolean;
    /**
     * Сортировка.
     */
    sortOrder?: number;
    /**
     * Контакты.
     */
    contacts?: Array<EditCommitteeContact>;
    /**
     * Идентификатор.
     */
    id?: string;
}

