/**
 * Тип пункта дополнительного меню
 * @param {string} title - Название пункта
 * @param {string} icon - Иконка пункта (taiga-ui, lucide)
 * @param {boolean} visible - Видимость пункта
 * @param action - Действие, которое будет выполнено при нажатии на этот пункт
 */
export interface MoreMenuItem {
  title: string;
  visible?: boolean;
  icon?: string;
  action: () => void;
}
