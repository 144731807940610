import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  AddOrganisation,
  EditOrganisation,
  Id,
  OrganisationView,
  OrganisationService as ApiOrganisationService,
  OrganisationSearchParameters,
  OrganisationWithInvoiceIdView,
  ContactsForOrganisationService,
} from '@src/api';
import { OrganisationResponseUI, OrganisationUI, OrganisationUpsert } from '@src/models';
import { AlertService, BaseSearchService, UserService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';
import { CustomNamesService } from '@src/app/modules/custom-name-tabs';

import { APP_CONFIG } from '../config';

export interface LoadOrganisationRequest {
  id: string;
  loadObjectMenu: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationService
  implements OnDestroy, BaseSearchService<OrganisationSearchParameters, any, OrganisationResponseUI>
{
  organisation$ = new BehaviorSubject<OrganisationUI | null>(null);
  organisationInfo$ = new BehaviorSubject<OrganisationUI | null>(null);
  currentParentOrganisation$: BehaviorSubject<OrganisationView | undefined>;
  parentOrganisations$: BehaviorSubject<OrganisationUI[]>;
  childOrganisationList$: BehaviorSubject<OrganisationView[]>;
  organisationList$: BehaviorSubject<OrganisationView[] | null>;

  private destroyed$$ = new Subject<void>();
  private getOrganisation$ = new BehaviorSubject<LoadOrganisationRequest | null>(null);
  private getOrganisationInfo$ = new BehaviorSubject<string | null>(null);

  filter$ = new BehaviorSubject<OrganisationSearchParameters | null>(null);
  sort$ = new BehaviorSubject<null>(null);

  pagination$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private organisationService: ApiOrganisationService,
    private contactsForOrganisationService: ContactsForOrganisationService,
    private userService: UserService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly customNamesService: CustomNamesService,
  ) {
    this.currentParentOrganisation$ = new BehaviorSubject<OrganisationView | undefined>(undefined);
    this.parentOrganisations$ = new BehaviorSubject([{}]);
    this.childOrganisationList$ = new BehaviorSubject([{}]);
    this.organisationList$ = new BehaviorSubject<OrganisationView[] | null>(null);

    this.getOrganisation()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(organisation => {
        this.organisation$.next(!!organisation ? this.normalizeOrganisationDataForUI(organisation) : null);
      });

    this.getOrganisationInfo()
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(organisation => {
        this.organisationInfo$.next(!!organisation ? this.normalizeOrganisationDataForUI(organisation) : null);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getOrganisationList(top: number = 1000, start: number = 0): void {
    this.organisationService
      .organisationList(top, start)
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        map(organisationList => this.sortingOrganisations(organisationList)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(organisationList => this.organisationList$.next(organisationList));
  }

  // TODO: возможно можно объединить в методом getOrganisation
  getOrganisationData(id: string): Promise<OrganisationView> {
    return lastValueFrom(this.organisationService.getOrganisation(id)).then();
  }

  loadOrganisation(loadCommittee: LoadOrganisationRequest): void {
    this.getOrganisation$.next(loadCommittee);
  }

  getOrganisation(): Observable<OrganisationWithInvoiceIdView | null> {
    return this.getOrganisation$
      .pipe(
        filter(loadOrganisation => !!loadOrganisation),
        switchMap(loadOrganisation => {
          return this.organisationService.getOrganisation(loadOrganisation?.id!).pipe(
            catchError(() => {
              this.alertService.error(
                this.translateService.instant('components.organisation.alerts.errors.organisationNotFound'),
              );
              return of(null);
            }),
            map(organisation => {
              return organisation ? this.sortingOrganisationContacts(organisation) : null;
            }),
            switchMap(organisation => {
              if (!!organisation && loadOrganisation?.loadObjectMenu) {
                this.customNamesService.getObjectMenuWCustom(loadOrganisation!.id);
              }

              return of(organisation);
            }),
          );
        }),
      )
      .pipe(
        map(organisation => {
          return organisation ?? null;
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  loadOrganisationInfo(organisationId: string): void {
    this.getOrganisationInfo$.next(organisationId);
  }

  getOrganisationInfo(): Observable<OrganisationWithInvoiceIdView | null> {
    return this.getOrganisationInfo$.pipe(takeUntil(this.destroyed$$)).pipe(
      filter(organisationId => !!organisationId),
      switchMap(organisationId => {
        return this.organisationService.getOrganisation(organisationId as string).pipe(
          catchError(() => {
            this.alertService.error(
              this.translateService.instant('components.organisation.alerts.errors.organisationNotFound'),
            );
            return of(null);
          }),
          map(organisation => {
            return organisation ? this.sortingOrganisationContacts(organisation) : null;
          }),
        );
      }),
    );
  }

  getParentOrganisation(all: boolean): void {
    this.organisationService
      .getParentOrganisation(all)
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(organisations => {
        if (all) {
          this.parentOrganisations$.next(organisations);
          const currentParentOrganisation = organisations.find(
            organisation => organisation.id && organisation.id === this.userService.authUser?.parentOrganisationId,
          );
          this.currentParentOrganisation$.next(currentParentOrganisation);
        } else {
          this.currentParentOrganisation$.next(organisations?.[0]);
        }
      });
  }

  getChildOrganisationList(id: string): void {
    this.organisationService
      .getChildOrganisationList(id)
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          return throwError(err);
        }),
        map(childOrganisationList => this.sortingOrganisations(childOrganisationList)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(childOrganisationList =>
        this.childOrganisationList$.next(
          childOrganisationList.map(organisation => this.normalizeOrganisationDataForUI(organisation)),
        ),
      );
  }

  createOrganisation(data: AddOrganisation): Observable<Id> {
    return this.organisationService.addOrganisation(data).pipe(
      catchError(errorMessage => {
        this.alertService.error(errorMessage);
        return throwError(errorMessage);
      }),
    );
  }

  // TODO: возможно лучше добавить метод загрузки фото к организации
  editOrganisation(data: OrganisationUpsert): Observable<Id> {
    const orgObs = this.organisationService.editOrganisation(data as EditOrganisation).pipe(
      catchError(errorMessage => {
        this.alertService.error(errorMessage);
        return throwError(errorMessage);
      }),
    );

    return orgObs;
  }

  deleteOrganisation(id: string): Observable<Id> {
    return this.organisationService.deleteOrganisation(id).pipe(
      catchError(errorMessage => {
        this.alertService.error(errorMessage, {
          label: this.translateService.instant('common.alerts.errors.error2'),
          autoClose: false,
        });
        return throwError(errorMessage);
      }),
    );
  }

  search(pagination: number, filter: OrganisationSearchParameters): Observable<OrganisationResponseUI> {
    const top = APP_CONFIG.loadingLimit.organisations;
    const start = pagination * APP_CONFIG.loadingLimit.organisations;

    return this.organisationService.searchForOrganisations(filter, top, start).pipe(
      map(response => {
        response.result = response.result?.map(item => this.normalizeOrganisationDataForUI(item));

        return response;
      }),
      catchError(errorMessage => {
        this.alertService.error(errorMessage);
        return throwError(() => errorMessage);
      }),
    );
  }

  resetOrganisation(parentOrganisationId?: string): void {
    this.organisation$.next({ parentOrganisation: parentOrganisationId });
  }

  resetOrganisationInfo(parentOrganisationId?: string): void {
    this.organisationInfo$.next({ parentOrganisation: parentOrganisationId });
  }

  resetParentOrganisation(): void {
    this.parentOrganisations$.next([{}]);
  }

  resetChildOrganisationList(): void {
    this.childOrganisationList$.next([{}]);
  }

  resetAll(): void {
    this.resetOrganisation();
    this.resetChildOrganisationList();
  }

  private normalizeOrganisationDataForUI(data: OrganisationUI) {
    data.businessTypesComplected = data.businessTypes?.map(businessType => businessType.name).join(', ');
    return data;
  }

  private sortingOrganisations(organisations: OrganisationView[]): OrganisationView[] {
    const userOrganisationIds = this.userService.authUser?.organisationJobTitles
      ?.map(organisation => organisation.organisationId)
      .filter((value, index, arr) => arr.indexOf(value) === index);

    organisations.sort((org1, org2) => {
      let orgName1 = org1.shortName?.trim().toLowerCase();
      let orgName2 = org2.shortName?.trim().toLowerCase();
      if (!orgName1) return 1;
      if (!orgName2) return -1;

      if (userOrganisationIds?.includes(org1.id)) {
        orgName1 = ` ${orgName1}`;
      }
      if (userOrganisationIds?.includes(org2.id)) {
        orgName2 = ` ${orgName2}`;
      }

      if (orgName1 < orgName2) return -1;
      if (orgName1 > orgName2) return 1;
      return 0;
    });

    return organisations;
  }

  private sortingOrganisationContacts(organisation: OrganisationView): OrganisationView {
    organisation.contacts
      ?.sort((a, b) => a.sortOrder! - b.sortOrder!)
      .map((contact, index) => {
        contact.sortOrder = (index + 1) * 10;
        return contact;
      });

    return organisation;
  }

  private showError(title: string, err: unknown) {
    const message = err instanceof Error ? err.message : err;
    this.alertService.error(`${title}\n${message}`);
  }
}
