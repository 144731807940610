import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppService, PreferencesService } from '@src/core/services';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiBrightness, TuiDialogService } from '@taiga-ui/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { StorageKeys } from '@src/constants/storage';
import { AuthService, StorageService } from '@src/app/modules/auth';
import { BrandingService } from '@src/app/modules/branding';
import { ThemeSwitcherService, ThemeBrightnessEnum } from '@src/app/modules/theme-switcher';
import { UpdateAppDialogComponent, OfflineDialogComponent, ManyTabsDialogComponent } from '@src/app/shared/dialogs';
import { TelegramAuthService } from 'src/app/modules/telegram';

const APP_NAME = 'UNIONS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isDark$ = this.themeSwitcherService.isDark$;

  private offlineDialogSubscription?: Subscription;
  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmUpdateAppDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(UpdateAppDialogComponent, this.injector),
    {
      closeable: false,
      dismissible: false,
      size: 's',
    },
  );
  private readonly offlineDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(OfflineDialogComponent, this.injector),
    {
      closeable: false,
      dismissible: false,
      size: 's',
    },
  );
  private readonly manyTabsDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(ManyTabsDialogComponent, this.injector),
    {
      closeable: false,
      dismissible: false,
      size: 'l',
    },
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly titleService: Title,
    private readonly brandingService: BrandingService,
    private readonly appService: AppService,
    private readonly themeSwitcherService: ThemeSwitcherService,
    private translateService: TranslateService,
    private readonly storageService: StorageService,
    private readonly telegramAuthService: TelegramAuthService,
    private readonly authService: AuthService,
    private readonly preferencesService: PreferencesService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    window.addEventListener('online', event => this.updateOnlineStatus(event));
    window.addEventListener('offline', event => this.updateOnlineStatus(event));
  }

  ngOnInit(): void {
    const storageAction = (e: StorageEvent) => {
      if (e.key === StorageKeys.OpenPages && e.newValue) {
        if (!this.authService.isLogged()) {
          this.manyTabsDialog.pipe(takeUntil(this.destroyed$$)).subscribe();
          return;
        }

        this.telegramAuthService.setActiveTab(false);
        this.storageService.removeItem(StorageKeys.OpenPages);
        window.removeEventListener('storage', storageAction);
      }
    };

    window.addEventListener('storage', storageAction); // storageAction срабатывает во всех вкладках, кроме текущей

    this.telegramAuthService.isLogged$.subscribe(isLogged => {
      if (isLogged || !this.authService.isLogged()) {
        this.storageService.setItem(StorageKeys.OpenPages, Date.now().toString()); // Записываем в Local storage дату, чтобы во всех других вкладках сработал EventListener 'storage'
      }
    });

    this.brandingService.data$.subscribe(brandData => {
      let appTitle = APP_NAME;
      if (this.brandingService.isBrand() && brandData) {
        appTitle = brandData.shortName ?? this.translateService.instant('common.labels.noData');

        if (this.themeSwitcherService.getTheme() === null && brandData.darkThemeByDefault) {
          this.themeSwitcherService.setTheme(true);
        }
      }

      this.titleService.setTitle(appTitle);
      this.cdr.markForCheck();
    });

    this.preferencesService.settings$.subscribe(settings => {
      if (this.themeSwitcherService.getTheme() === null && settings?.darkThemeByDefault) {
        this.themeSwitcherService.setTheme(true);
      }
    });

    this.appService.isCurrentVersionUnsupported().then(res => {
      if (res === true) this.openConfirmUpdateAppDialog();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  get mode(): TuiBrightness | null {
    return this.isDark$.value ? ThemeBrightnessEnum.Dark : null;
  }

  openConfirmUpdateAppDialog(): void {
    const brandData = this.brandingService.data$.value;

    this.confirmUpdateAppDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.appService.updateApplicationVersion(brandData);
        }
      },
    });
  }

  private updateOnlineStatus(event: Event) {
    const condition = event.type;

    if (condition === 'offline') {
      this.offlineDialogSubscription = this.offlineDialog.pipe(takeUntil(this.destroyed$$)).subscribe();
    } else if (condition === 'online') {
      this.offlineDialogSubscription?.unsubscribe();
    }
  }
}
