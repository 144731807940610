import { TuiActiveZoneDirective } from '@taiga-ui/cdk';

interface HeaderButton {
  label: string;
  icon?: string;
  visible: () => boolean;
  action: () => void;
  activeZone?: (active: boolean, activeZoneHost: TuiActiveZoneDirective) => void;
}

export interface HeaderOptions {
  title: string;
  buttons?: {
    left?: HeaderButton;
    right?: HeaderButton;
  };
}
