import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '@src/core/services';
import { ObjectId } from '@src/types/id';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  readonly allowEditing$ = new BehaviorSubject(false);
  readonly allowIsDefaultOrganisationEditing$ = new BehaviorSubject(false);
  readonly allowDeleting$ = new BehaviorSubject(false);
  readonly allowSpecialFieldsViewing$ = new BehaviorSubject(false);
  readonly allowSpecialFieldsEditing$ = new BehaviorSubject(false);
  readonly allowSpecialFieldsForAssociationViewing$ = new BehaviorSubject(false);
  readonly allowSpecialFieldsForAssociationEditing$ = new BehaviorSubject(false);
  readonly allowJobTitleFieldsEditing$ = new BehaviorSubject(false);
  readonly allowJobTitlesForAdminOnlyEditing$ = new BehaviorSubject(false);
  readonly allowAssociationEmployeeEditing$ = new BehaviorSubject(false);
  readonly allowOrganisationEmployeeEditing$ = new BehaviorSubject(false);
  readonly allowPhoneFieldViewing$ = new BehaviorSubject(false);
  readonly allowRoleFieldViewing$ = new BehaviorSubject(false);
  readonly allowEmailFieldViewing$ = new BehaviorSubject(false);
  readonly allowEmailFieldEditing$ = new BehaviorSubject(false);
  readonly allowContactFieldCreating$ = new BehaviorSubject(false);
  readonly allowContactFieldEditing$ = new BehaviorSubject(false);
  readonly allowContactFieldDeleting$ = new BehaviorSubject(false);
  readonly allowOnlyYourShortProfileViewing$ = new BehaviorSubject(false);
  readonly allowOnlyYourShortProfileEditing$ = new BehaviorSubject(false);

  private readonly permissions = this.ngxPermissionsService.getPermissions();

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly userService: UserService,
  ) {
    this.userService.authUser$.subscribe(user => {
      if (!user) {
        return;
      }

      this.getPermissions(undefined, user.id);
    });
  }

  getPermissions(organisationId?: string | null, userId?: ObjectId | null) {
    const authUser = this.userService.authUser$.value;

    if (!authUser || !authUser.organisationId) return;

    const permissionsForOrganisation = organisationId ? authUser.permissionsForOrganisations?.[organisationId] : [];

    // Разрешение редактирования пользователя
    this.allowEditing$.next(
      'organisationEmployeeEditing' in this.permissions ||
        permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeEditing') ||
        authUser.id === userId,
    );

    // Разрешение редактирования дефолтной организации пользователя
    this.allowIsDefaultOrganisationEditing$.next(
      'organisationEmployeeEditing' in this.permissions || authUser.id === userId,
    );

    // Разрешение удаления пользователя
    this.allowDeleting$.next(
      'organisationEmployeeDeleting' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeDeleting'),
    );

    // Разрешение для просмотра поля Телефон
    this.allowPhoneFieldViewing$.next(
      'organisationEmployeePhoneFieldViewing' in this.permissions ||
        'organisationEmployeeSpecialFieldsViewing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsViewing'),
    );

    // Разрешение для просмотра поля Роль
    this.allowRoleFieldViewing$.next(
      'organisationEmployeeSpecialFieldsViewing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsViewing'),
    );

    // Разрешение для редактирования полей Телефон и Роль
    this.allowSpecialFieldsEditing$.next(
      'organisationEmployeeSpecialFieldsEditing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsEditing'),
    );

    // Разрешения для поля Email
    this.allowEmailFieldViewing$.next(
      'organisationEmployeeEmailFieldViewing' in this.permissions ||
        'organisationEmployeeSpecialFieldsViewing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsViewing') ||
        authUser.id === userId,
    );

    this.allowEmailFieldEditing$.next(
      'organisationEmployeeSpecialFieldsEditing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeSpecialFieldsEditing') ||
        authUser.id === userId,
    );

    // Разрешения для полей Куратор, Взнос не оплачен и Функциональные должности
    this.allowSpecialFieldsForAssociationViewing$.next(
      'organisationEmployeeSpecialFieldsForAssociationViewing' in this.permissions || authUser.id === userId,
    );

    this.allowSpecialFieldsForAssociationEditing$.next(
      'organisationEmployeeSpecialFieldsForAssociationEditing' in this.permissions,
    );

    // Разрешение для поля Должность в компании
    this.allowJobTitleFieldsEditing$.next(
      'organisationEmployeeJobTitleFieldsEditing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeJobTitleFieldsEditing') ||
        ('onlyYourJobTitleFieldsEditing' in this.permissions && authUser.id === userId),
    );

    // Разрешение для фильтрации списка Функциональные должности
    this.allowJobTitlesForAdminOnlyEditing$.next(
      'organisationEmployeeJobTitlesForAdminOnlyEditing' in this.permissions,
    );

    // Разрешения для полей Должность в компании, Функциональные должности и Роль
    this.allowAssociationEmployeeEditing$.next('organisationEmployeeEditing' in this.permissions);
    // TODO: Вроде нужно объединить с условием выше через || (или), по аналогии с остальными разрешениями
    this.allowOrganisationEmployeeEditing$.next(
      !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeEditing'),
    );

    // Разрешения для Доп. полей (контактов)
    this.allowContactFieldCreating$.next(
      'contactsCreating' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeContactsCreating'),
    );

    this.allowContactFieldEditing$.next(
      'contactsEditing' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeContactsEditing'),
    );

    this.allowContactFieldDeleting$.next(
      'contactsDeleting' in this.permissions ||
        !!permissionsForOrganisation?.includes('onlyYourOrganisationEmployeeContactsDeleting'),
    );

    // Разрешения для урезанного профиля (режим Киоска для Наблюдателя)
    this.allowOnlyYourShortProfileViewing$.next('onlyYourShortProfileViewing' in this.permissions);

    this.allowOnlyYourShortProfileEditing$.next('onlyYourShortProfileEditing' in this.permissions);
  }
}
