import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { FieldType, FieldTypeName } from './types';

export const FIELD_TYPES: Record<FieldTypeName, FieldType> = {
  // TODO: Типы которые нужно брать с бэка (нужно API)
  phone: {
    id: 1,
    description: marker('components.field.constants.fieldTypes.phone'),
    forContacts: true,
  },
  email: {
    id: 2,
    description: 'Email',
    forContacts: true,
  },
  'web-page': {
    id: 3,
    description: marker('components.field.constants.fieldTypes.webPage'),
    forContacts: true,
  },
  'web-document': {
    id: 4,
    description: marker('components.field.constants.fieldTypes.webDocument'),
    forContacts: true,
  },
  'social-network': {
    id: 5,
    description: marker('components.field.constants.fieldTypes.socialNetwork'),
    forContacts: true,
  },
  messenger: {
    id: 6,
    description: marker('components.field.constants.fieldTypes.messenger'),
    forContacts: true,
  },
  file: {
    id: 8,
    description: marker('components.field.constants.fieldTypes.file'),
    forContacts: true,
  },
  text: {
    id: 7,
    description: marker('components.field.constants.fieldTypes.text'),
    forContacts: true,
  },
  'web-document-for-admin': {
    id: 11,
    description: marker('components.field.constants.fieldTypes.webDocumentForAdmin'),
    forContacts: true,
    permissions: ['contactsTypeForAdminViewing'],
  },
  'file-for-admin': {
    id: 13,
    description: marker('components.field.constants.fieldTypes.fileForAdmin'),
    forContacts: true,
    permissions: ['contactsTypeForAdminViewing'],
  },
  'text-for-admin': {
    id: 9,
    description: marker('components.field.constants.fieldTypes.textForAdmin'),
    forContacts: true,
    permissions: ['contactsTypeForAdminViewing'],
  },
  'web-document-for-admin-and-user': {
    id: 12,
    description: marker('components.field.constants.fieldTypes.webDocumentForAdminAndUser'),
    forContacts: true,
  },
  'file-for-admin-and-user': {
    id: 14,
    description: marker('components.field.constants.fieldTypes.fileForAdminAndUser'),
    forContacts: true,
  },
  'text-for-admin-and-user': {
    id: 10,
    description: marker('components.field.constants.fieldTypes.textForAdminAndUser'),
    forContacts: true,
  },

  // Типы которые используются только на фронте
  user: {
    id: 100,
    description: marker('components.field.constants.fieldTypes.user'),
    forContacts: false,
  },
  organisation: {
    id: 101,
    description: marker('components.field.constants.fieldTypes.organisation'),
    forContacts: false,
  },
  'files-list': {
    id: 102,
    description: marker('components.field.constants.fieldTypes.filesList'),
    forContacts: false,
  },
  'parse-links': {
    id: 103,
    description: marker('components.field.constants.fieldTypes.text'),
    forContacts: false,
  },
  committee: {
    id: 104,
    description: marker('components.field.constants.fieldTypes.committee'),
    forContacts: false,
  },
  'new-text': {
    id: 105,
    description: marker('components.field.constants.fieldTypes.text'),
    forContacts: false,
  },
  'new-user': {
    id: 106,
    description: marker('components.field.constants.fieldTypes.user'),
    forContacts: false,
  },
  'new-committee': {
    id: 107,
    description: marker('components.field.constants.fieldTypes.committee'),
    forContacts: false,
  },
  'new-web-page': {
    id: 108,
    description: marker('components.field.constants.fieldTypes.webPage'),
    forContacts: false,
  },
  'new-parse-links': {
    id: 109,
    description: marker('components.field.constants.fieldTypes.text'),
    forContacts: false,
  },
};
