<form class="user-info-view" [formGroup]="form">
  <div class="user-info-view__block">
    <app-photo
      class="image"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [text]="data | userDisplayableName : 'short'"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
    ></app-photo>

    <app-field
      class="field"
      tabindex="2"
      [name]="'components.userInfo.fields.lastName' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="form.get('lastName')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      class="field"
      tabindex="3"
      [name]="'components.userInfo.fields.firstName' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="form.get('firstName')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="form.get('middleName')?.value"
      class="field"
      tabindex="4"
      [name]="'components.userInfo.fields.middleName' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="form.get('middleName')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="form.get('birthDate')?.value"
      class="field"
      tabindex="5"
      [name]="'components.userInfo.fields.birthDate' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="form.get('birthDate')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <ng-container *ngIf="!(allowOnlyYourShortProfileViewing$ | async)">
      <app-field
        *ngIf="form.get('phone')?.value && (allowPhoneFieldViewing$ | async)"
        tabindex="6"
        [name]="'components.userInfo.fields.mobilePhone' | translate"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [typeName]="'phone'"
        [value]="form.get('phone')?.value"
        [size]="tuiElementLargeSize"
      ></app-field>
    </ng-container>

    <app-field
      *ngIf="form.get('email')?.value && (allowEmailFieldViewing$ | async)"
      class="field"
      tabindex="7"
      [name]="'components.userInfo.fields.email' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'email'"
      [value]="form.get('email')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <ng-container *ngIf="!(allowOnlyYourShortProfileViewing$ | async)">
      <ng-container *ngIf="form.get('defaultJobTitlesIds')?.value?.length">
        <tui-multi-select
          *tuiLet="jobsForViewing$ | async as jobs"
          tabindex="8"
          formControlName="defaultJobTitlesIds"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [expandable]="true"
          [editable]="false"
          [stringify]="stringifyJobTitle"
          [readOnly]="true"
        >
          {{ 'field.jobTitle' | customNames }}
          <tui-data-list *tuiDataList tuiMultiSelectGroup>
            <button *ngFor="let job of jobs" tuiOption [value]="job.id">
              {{ job.jobTitleName }}
            </button>
          </tui-data-list>
        </tui-multi-select>
      </ng-container>

      <app-field
        *ngIf="form.get('resources')?.value"
        class="field"
        tabindex="9"
        [name]="'components.userInfo.fields.resources' | translate"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [typeName]="'text'"
        [value]="form.get('resources')?.value"
        [size]="tuiElementLargeSize"
      ></app-field>

      <app-field
        *ngIf="form.get('hobbies')?.value"
        class="field"
        tabindex="10"
        [name]="'components.userInfo.fields.hobbies' | translate"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [typeName]="'text'"
        [value]="form.get('hobbies')?.value"
        [size]="tuiElementLargeSize"
      ></app-field>

      <ng-container *ngIf="allowSpecialFieldsForAssociationViewing$ | async">
        <app-field
          *ngIf="data?.curatorId"
          class="field"
          tabindex="11"
          [name]="'components.userInfo.fields.curator' | translate"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [typeName]="'user'"
          [value]="data?.curatorId"
          [size]="tuiElementLargeSize"
        ></app-field>

        <tui-input-date
          *ngIf="form.get('authorityValidTill')?.value"
          tabindex="12"
          formControlName="authorityValidTill"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [readOnly]="true"
        >
          {{ 'components.userInfo.fields.authorityValidTill' | translate }}
        </tui-input-date>

        <app-field
          *ngIf="data && data.paymentDetailsId"
          tabindex="13"
          [name]="'label.userProfileAttachment' | customNames"
          [typeName]="'file'"
          [value]="data.paymentDetailsId"
          [size]="tuiElementLargeSize"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        ></app-field>
      </ng-container>

      <tui-checkbox-block
        *ngIf="(allowSpecialFieldsForAssociationViewing$ | async) && form.value.isFeePaid"
        class="field"
        tabindex="14"
        formControlName="isFeePaid"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [readOnly]="true"
        [size]="tuiElementSmallSize"
      >
        {{ 'components.userInfo.fields.feeNotPaid' | translate }}
      </tui-checkbox-block>
    </ng-container>
  </div>

  <ng-container *ngIf="!(allowOnlyYourShortProfileViewing$ | async)">
    <div class="user-info-view__contacts">
      <div *ngFor="let contact of form.get('contacts')?.value" class="contact">
        <app-field
          *ngIf="contact.contact"
          class="field"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [typeId]="contact.contactTypeId"
          [name]="contact.description"
          [value]="contact.contact"
          [size]="tuiElementLargeSize"
        ></app-field>
      </div>
    </div>

    <div class="user-info-view__organisations" formArrayName="organisations">
      <tui-island *ngFor="let organisation of organisations.controls; let oIndex = index" class="tui-island">
        <tui-svg
          *ngIf="organisation.get('isDefault')?.value"
          class="tui-island__icon tui-island__icon_isDefault"
          [src]="'tuiIconStar'"
        ></tui-svg>
        <app-link
          class="tui-island__title"
          [routerLink]="'/organisation/' + organisation.get('organisationId')?.value"
          [displayText]="organisation.get('organisationName')?.value"
        ></app-link>

        <div class="tui-island__paragraph item" [formGroupName]="oIndex">
          <app-field
            tabindex="15"
            [name]="'components.userInfo.fields.jobTitlesDescription' | translate"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [typeName]="'text'"
            [value]="organisation.get('jobTitlesDescription')?.value"
            [size]="tuiElementLargeSize"
          ></app-field>

          <ng-container
            *ngIf="!organisation.get('isDefault')?.value && organisation.get('jobTitlesIds')?.value?.length"
          >
            <tui-multi-select
              *tuiLet="jobsForViewing$ | async as jobs"
              tabindex="16"
              formControlName="jobTitlesIds"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [tuiTextfieldCleaner]="true"
              [expandable]="true"
              [editable]="false"
              [stringify]="stringifyJobTitle"
              [readOnly]="true"
            >
              {{ 'field.jobTitle' | customNames }}
              <tui-data-list *tuiDataList tuiMultiSelectGroup>
                <button *ngFor="let job of jobs" tuiOption [value]="job.id">
                  {{ job.jobTitleName }}
                </button>
              </tui-data-list>
            </tui-multi-select>
          </ng-container>

          <div *ngIf="allowRoleFieldViewing$ | async">
            <tui-select
              *tuiLet="roles$ | async as roles"
              tabindex="17"
              formControlName="roleIds"
              [class.tui-skeleton]="loading || !roles?.length"
              [class.tui-skeleton_text]="loading || !roles?.length"
              [valueContent]="roles ? rolesStringify(roles) : ''"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [readOnly]="true"
            >
              {{ 'components.userInfo.fields.role' | translate }}
              <tui-data-list *tuiDataList>
                <button *ngFor="let role of roles" tuiOption [value]="role.id">
                  {{ role.description }}
                </button>
              </tui-data-list>
            </tui-select>
          </div>
        </div>
      </tui-island>
    </div>
  </ng-container>

  <div *ngIf="!loading" class="user-info-view__buttons">
    <ng-container *ngIf="authUser$ | async as authUser">
      <ng-container *ngxPermissionsOnly="'userChatButtonViewing'">
        <div *ngIf="data && data.telegramId && data.telegramId !== authUser.telegramId" waitTelegramChats>
          <button
            tuiButton
            class="user-info-view-button"
            tabindex="18"
            type="button"
            appearance="primary"
            [size]="tuiElementLargeSize"
            (click)="onClickChatButton()"
          >
            {{ 'common.buttons.write' | translate }}
          </button>
        </div>
      </ng-container>

      <button
        *ngIf="allowEditing$ | async"
        tuiButton
        class="user-info-view-button"
        tabindex="19"
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="onClickEditButton()"
      >
        {{ 'common.buttons.edit' | translate }}
      </button>

      <button
        *ngIf="data && data.id && data.id !== authUser.id && (allowDeleting ?? (allowDeleting$ | async))"
        tuiButton
        class="user-info-view-button"
        tabindex="20"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeleteButton()"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>

      <button
        *ngIf="reportsButtonVisible"
        tuiButton
        class="user-info-view-button"
        tabindex="21"
        type="button"
        appearance="positive"
        [size]="tuiElementLargeSize"
        (click)="showReportsDialog(reportsTmp)"
      >
        {{ 'common.buttons.reports' | translate }}
      </button>
    </ng-container>
  </div>

  <div class="user-info-view__block">
    <div
      *ngIf="committeesList?.length"
      class="field"
      tabindex="22"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <div class="committees-list-title">{{ 'components.userInfo.labels.consist' | translate }}</div>
      <app-list
        listType="committees"
        mode="view"
        [items]="committeesList"
        (selectedIdChange)="onSelectedCommitteeIdChange($event)"
      ></app-list>
    </div>
  </div>
</form>

<ng-template #reportsTmp>
  <app-reports [reportType]="'users'" [hiddenReportData]="{ userId: data?.id }"></app-reports>
</ng-template>
