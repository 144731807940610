<div class="header">
  <ng-container *ngIf="options.buttons?.left as leftButton">
    <button
      #leftButtonZone="tuiActiveZone"
      *ngIf="leftButton.visible()"
      tuiButton
      class="header__button header__button_left"
      type="button"
      appearance="link"
      [icon]="leftButton.icon || ''"
      size="s"
      [title]="leftButton.label"
      (tuiActiveZoneChange)="leftButton.activeZone ? leftButton.activeZone($event, leftButtonZone) : null"
      (click)="leftButton?.action()"
    >
      {{ leftButton.label }}
    </button>
  </ng-container>

  <div class="header__title">{{ options.title }}</div>

  <ng-container *ngIf="options.buttons?.right as rightButton">
    <button
      #rightButtonZone="tuiActiveZone"
      *ngIf="rightButton.visible()"
      tuiButton
      class="header__button header__button_right"
      type="button"
      appearance="link"
      [icon]="rightButton.icon || ''"
      size="s"
      [title]="rightButton.label"
      (click)="rightButton.action()"
      (tuiActiveZoneChange)="rightButton.activeZone ? rightButton.activeZone($event, rightButtonZone) : null"
    >
      {{ rightButton.label }}
    </button>
  </ng-container>
</div>
