import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { AlertService } from '@src/core/services';
import { SubscriptionsForOrganisationView } from '@src/api';
import { APP_CONFIG } from '@src/core';
import { UserUI } from '@src/models';
import { TranslateService } from '@ngx-translate/core';

import { SubscriptionsForOrganisationService } from './subscriptions-for-organisation.service';
import { SubscriptionsForUsersService } from './subscriptions-for-users.service';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { DialogConfirmCommonComponent } from '@src/app/shared/dialogs';

const MAIN_BOT = APP_CONFIG.bots.main;

@Injectable({
  providedIn: 'root',
})
export class SettingService implements OnDestroy {
  associationDefaultSubscriptions?: SubscriptionsForOrganisationView[];
  private destroyed$$: Subject<void> = new Subject<void>();
  private userChatIds?: number[];

  constructor(
    private messengerService: TelegramMessengerService,
    private subscriptionsForOrganisationService: SubscriptionsForOrganisationService,
    private subscriptionsForUsersService: SubscriptionsForUsersService,
    private ngxPermissionsService: NgxPermissionsService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  initAutoSubscriptions(authUser: UserUI) {
    this.messengerService.getAllChatIds().then(allChatIds => {
      this.userChatIds = allChatIds;
      this.dialogService
        .open<boolean>(new PolymorpheusComponent(DialogConfirmCommonComponent, this.injector), {
          label: this.translateService.instant('common.dialogs.autoSubscriptionsHeader'),
          data: this.translateService.instant('common.dialogs.autoSubscriptionsDescription'),
          closeable: false,
          dismissible: false,
          size: 's',
        })
        .subscribe(confirm => {
          if (confirm) {
            this.unlockBots().then(() => this.onSubscribeToUnionsBots());
          }
        });

      const permissions = this.ngxPermissionsService.getPermissions();

      if (!authUser?.parentOrganisationId || !('associationChannelAutoSubscribing' in permissions)) return;

      this.dialogService
        .open<boolean>(new PolymorpheusComponent(DialogConfirmCommonComponent, this.injector), {
          label: 'Автоматическая подписка на групповые чаты и каналы',
          data: 'Автоматическая подписка на групповые чаты и каналы',
          closeable: false,
          dismissible: false,
          size: 's',
        })
        .subscribe(confirm => {
          if (confirm && authUser.parentOrganisationId) {
            lastValueFrom(
              this.subscriptionsForOrganisationService.getSubscriptionsData(authUser.parentOrganisationId),
            ).then(associationSubscriptions => {
              this.associationDefaultSubscriptions = associationSubscriptions.filter(
                associationSubscription => associationSubscription.isDefault,
              );
              this.onSubscribeToAssociationChats();
              this.checkUserPrivacySettingRules();
            });
          }
        });
    });
  }

  private async unlockBots() {
    const blockedSendersIds = await this.messengerService.getAllBlockedMessageSendersIds();

    if (blockedSendersIds.includes(MAIN_BOT.id)) {
      await this.messengerService.api.toggleMessageSenderIsBlocked(
        {
          _: 'messageSenderUser',
          userId: MAIN_BOT.id,
        },
        false,
      );
    }
  }

  private onSubscribeToUnionsBots(): void {
    if (!this.userChatIds) return;

    if (!this.userChatIds.includes(MAIN_BOT.id)) {
      this.subscribeToUnionsBot(MAIN_BOT.username, MAIN_BOT.id);
    }
  }

  private async onSubscribeToAssociationChats(): Promise<void> {
    if (!this.associationDefaultSubscriptions?.length) return;

    const userSubscriptionsIds = await lastValueFrom(this.subscriptionsForUsersService.getSubscriptionsIdsData());

    for (const associationDefaultSubscription of this.associationDefaultSubscriptions) {
      if (!this.userChatIds) return;
      //TODO: Проверка на добавленные чаты реализована в subscribeChannel. Проверить, можно ли убрать тут
      if (
        associationDefaultSubscription.id &&
        associationDefaultSubscription.codeName &&
        associationDefaultSubscription.chatId &&
        (!this.userChatIds.includes(associationDefaultSubscription.chatId) ||
          !userSubscriptionsIds.includes(associationDefaultSubscription.chatId))
      ) {
        await this.subscriptionsForUsersService.subscribeChannel(
          associationDefaultSubscription.id,
          associationDefaultSubscription.chatId,
          associationDefaultSubscription.codeName,
        );
      }
    }
  }

  private async checkUserPrivacySettingRules() {
    const privacySettingRulesChatInvites = await this.messengerService.api.getUserPrivacySettingRules({
      _: 'userPrivacySettingAllowChatInvites',
    });

    if (
      privacySettingRulesChatInvites.rules.length &&
      privacySettingRulesChatInvites.rules[0]._ !== 'userPrivacySettingRuleAllowAll'
    ) {
      this.alertService.warning(this.translateService.instant('common.alerts.warnings.forbidenChatInvites'), {
        label: this.translateService.instant('common.alerts.warnings.atention'),
      });

      // TODO: добавить возможность изменить настройки по нажанию на кнопку или дать возможность добавить список админов в контакты или просто убрать это оповещение
    }
  }

  private async subscribeToUnionsBot(username: string, telegramId: number): Promise<void> {
    await this.messengerService.api.searchPublicChat(username);
    await this.messengerService.api.openChat(telegramId);
    await this.messengerService.api.sendBotStartMessage(telegramId, telegramId);
  }
}
