<tui-scrollbar class="scroll">
  <div class="login" [class.branding]="brandingData">
    <div class="login__form">
      <div *ngIf="enterType === EnterType.Bot" class="loading-wrapper">
        <app-branding-logo></app-branding-logo>

        <h2 class="loading-wrapper__title">{{ 'common.labels.loading' | translate }}...</h2>
      </div>

      <app-login-enter-by-code
        *ngIf="enterType === EnterType.Email && !openPolicy"
        [title]="brandingData?.loginScreenTitle"
        [associationId]="brandingData?.id"
        [isBrand]="!!brandingData"
        [(phoneNumber)]="phoneNumber"
        (onSuccess)="onApplyToken($event)"
        (onLoading)="onLoading($event)"
      >
        <ng-container [ngTemplateOutlet]="notificationLoginTmp"></ng-container>

        <ng-container [ngTemplateOutlet]="registrationLink"></ng-container>

        <ng-container [ngTemplateOutlet]="privacyLink"></ng-container>

        <ng-container [ngTemplateOutlet]="mobileAppLinks"></ng-container>
      </app-login-enter-by-code>

      <app-login-policy
        *ngIf="openPolicy"
        [associationId]="brandingData?.id"
        [associationShortName]="brandingData?.shortName"
        [privacyPolicyLink]="brandingData?.privacyPolicyLink"
        [enterType]="enterType"
        (close)="openPolicy = false"
      ></app-login-policy>

      <ng-template #registrationLink>
        <button
          *ngIf="brandingData?.allowUserRegistrationForm"
          tuiButton
          size="l"
          appearance="outline-blue"
          [class.disabled-link]="loading"
          [routerLink]="['/registration', brandingData?.id]"
        >
          {{ 'components.login.buttons.registration' | translate }}
        </button>
      </ng-template>

      <ng-template #privacyLink>
        <app-link class="privacy" color="grey" [class.disabled-link]="loading" (click)="openPolicy = true">
          {{ 'components.login.labels.privacyPart1' | translate }}
          <u>{{ 'components.login.labels.privacyPart2' | translate }}</u>
          {{ 'components.login.labels.privacyPart3' | translate }}
          <u>{{ 'components.login.labels.privacyPart4' | translate }}</u>
        </app-link>
      </ng-template>

      <ng-template #mobileAppLinks>
        <ng-container *ngIf="!env.isMobilePlatform">
          <app-mobile-app-download-links
            *ngIf="brandingData; else mobileLinksForUnions"
            class="mobile-app-download-links"
            [appStoreLink]="brandingData.appStoreLink"
            [googlePlayLink]="brandingData.googlePlayLink"
          ></app-mobile-app-download-links>

          <ng-template #mobileLinksForUnions>
            <app-mobile-app-download-links class="mobile-app-download-links"></app-mobile-app-download-links>
          </ng-template>
        </ng-container>
      </ng-template>

      <div *ngIf="brandingData?.loginScreenDescription" class="description">
        {{ brandingData?.loginScreenDescription }}
      </div>

      <div class="version">{{ APP_VERSION }}</div>
    </div>
  </div>
</tui-scrollbar>

<ng-template [tuiSheetOptions]="appSheetOptions" [(tuiSheet)]="appSheetOpen">
  <app-download-links [options]="downloadLinksOptions"></app-download-links>
</ng-template>

<tui-sheets-host ngProjectAs="tuiOverContent"></tui-sheets-host>

<ng-template #tmpWelcomeMessages let-observer>
  <app-auth-dialog (completed)="observer.complete()"></app-auth-dialog>
</ng-template>

<ng-template #notificationLoginTmp>
  <tui-notification *ngIf="!brandingData || brandingData.allowUserRegistrationForm" status="warning">
    {{ 'components.login.labels.notificationLogin' | translate }}
  </tui-notification>
</ng-template>
