import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { ViewMode } from '@src/models';

/**
 * Ищем в строке ссылки или почтовый адрес и даем пользователю возможность по ним перейти
 * */
@Component({
  selector: 'field-new-parse-links',
  templateUrl: './field-new-parse-links.component.html',
  styleUrls: ['./field-new-parse-links.component.scss', '../styles/field-new-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNewParseLinksComponent {
  @Input() mode?: ViewMode = 'view';

  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 'l';

  @Input() name?: string;

  @Input() value: string | null = null;
}
