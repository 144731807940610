<tui-hosted-dropdown
  *ngIf="!!currentParentOrganisation"
  class="association-menu__wrap"
  [content]="associationMenuTmpl"
  [sided]="true"
>
  <div class="association-menu">
    <tui-avatar
      class="association-menu__icon"
      [size]="mode === 'desktop' ? 'm' : 's'"
      [autoColor]="!currentParentOrganisation.photoId"
      [avatarUrl]="currentParentOrganisation.photoId | getImageSrc"
      [text]="currentParentOrganisation.shortName || ''"
    ></tui-avatar>

    <div *ngIf="mode === 'desktop' && isOpen" class="association-menu__text-box">
      <div *ngIf="currentParentOrganisation.shortName" class="association-menu__title">
        {{ currentParentOrganisation.shortName }}
      </div>
    </div>
  </div>
</tui-hosted-dropdown>

<ng-template #associationMenuTmpl>
  <tui-data-list *ngIf="parentOrganisations$ | async as parentOrganisations">
    <tui-opt-group [label]="'components.navigation.labels.associationMenu' | translate">
      <button
        *ngFor="let parentOrganisation of parentOrganisations"
        tuiOption
        class="association-menu-item"
        (click)="changeCurrentParentOrganisation(parentOrganisation)"
      >
        <tui-badged-content
          size="xs"
          [colorTop]="parentOrganisation.id === currentParentOrganisation?.id ? 'var(--tui-success-fill)' : ''"
        >
          <tui-avatar
            class="association-menu-item__icon"
            size="xs"
            [autoColor]="!parentOrganisation.photoId"
            [avatarUrl]="parentOrganisation.photoId | getImageSrc"
            [text]="parentOrganisation.shortName || ''"
          ></tui-avatar>
        </tui-badged-content>

        <span class="association-menu-item__title">
          {{ parentOrganisation.shortName || '' }}
        </span>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
