import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxPermissionsService, NgxRolesObject, NgxRolesService } from 'ngx-permissions';
import { RoleCode, ROLE_PERMISSIONS_DEFAULT, AuthUserService } from '@src/app/modules/auth';
import { UserUI } from '@src/models';
import { NavigationItem } from '@src/app/modules/navigation';
import { NAVIGATION_ITEMS_TOKEN } from '@src/app/app.module';
import { VisibilityEnum, CustomNamesService, CustomNamesItem } from '@src/app/modules/custom-name-tabs';
import { NotificationEventsOptionsModel, OrganisationSettingWithGplView } from '@src/api';
import { DYNAMIC_PERMISSIONS } from '@src/app/modules/auth/constants';
import { NotificationEventCodeEnum, NotificationsService } from '@src/app/modules/settings/notifications';

import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPermissionsService implements OnDestroy {
  private user?: UserUI;
  private readonly destroyed$$ = new Subject<void>();

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly ngxRolesService: NgxRolesService,
    private readonly customNamesService: CustomNamesService,
    private readonly preferencesService: PreferencesService,
    private readonly notificationsService: NotificationsService,
    private readonly authUserService: AuthUserService,
    @Inject(NAVIGATION_ITEMS_TOKEN) private navigationItems: NavigationItem[],
  ) {
    this.customNamesService.data$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.setData();
    });

    this.preferencesService.settings$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.setData();
    });

    this.notificationsService.notifications$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.setData();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  setData(user?: UserUI) {
    if (user) {
      this.user = user;
    }
    this.authUserService.resetPermissions();

    this.setDataByCustomNames(this.user, this.customNamesService.data$.value);
    this.setDataBySettings(this.user, this.preferencesService.settings$.value);
    this.setDataByNotifications(this.user, this.notificationsService.notifications$.value);
  }

  private async setDataByCustomNames(user?: UserUI, customNames: CustomNamesItem[] = []) {
    if (!user && !customNames) {
      return;
    }

    const roles = this.ngxRolesService.getRoles();

    customNames.forEach(customName => {
      const findNavigationItem = this.navigationItems.find(navigationItem => navigationItem.id === customName.codeName);

      // TODO: костыль, удалить, когда поправят бэк и отказаться от customNameItem, как раньше было использовать customName
      const customNameItem = this.getCustomName(customNames, customName);

      if (!!findNavigationItem && !!findNavigationItem.permissions) {
        switch (customNameItem.visibilityOption) {
          case VisibilityEnum.INVISIBLE:
            this.removePermissions(findNavigationItem.permissions);
            break;

          case VisibilityEnum.ADMINISTRATORS:
            if (!(RoleCode.AdminUO in roles || RoleCode.SysOp in roles)) {
              this.removePermissions(findNavigationItem.permissions);
            }
            break;

          case VisibilityEnum.ALL:
            break;

          default:
            this.removePermissions(findNavigationItem.permissions);
            break;
        }
      }
    });
  }

  private setDataBySettings(user?: UserUI, settings?: OrganisationSettingWithGplView) {
    if (!user?.roles || !settings) {
      return;
    }

    if (!user.roles.includes(RoleCode.AdminUO) && !user.roles.includes(RoleCode.SysOp)) {
      if (settings.showAllUsersPhonesAndEmails) {
        this.addPermissions(DYNAMIC_PERMISSIONS.showAllUsersPhonesAndEmails);
      }

      if (settings.showOnlyLoyaltyProgram) {
        const roles = this.ngxRolesService.getRoles();

        // TODO: Добавлено, чтобы разрешения на создание, редактирование объявлений не попали сотрудникам и наблюдателям
        const showOnlyLoyaltyProgramPermissions = DYNAMIC_PERMISSIONS.showOnlyLoyaltyProgram.filter(permission =>
          this.includePermissionInDefaultPermissions(permission, roles),
        );
        showOnlyLoyaltyProgramPermissions.push('showOnlyLoyaltyProgram');

        if (user.roles.includes(RoleCode.Spectator)) {
          showOnlyLoyaltyProgramPermissions.push('onlyYourShortProfileViewing'); // TODO: провести рефакторинг разрешения и работы с этим разрешением (сейчас используется только в режиме киоска для Наблюдателя)
          showOnlyLoyaltyProgramPermissions.push('onlyYourShortProfileEditing'); // TODO: провести рефакторинг разрешения и работы с этим разрешением (сейчас используется только в режиме киоска для Наблюдателя)
        }

        this.authUserService.resetPermissions(showOnlyLoyaltyProgramPermissions);
      }
    }

    if (settings.isMembersAllowedToPublishLocal === false) {
      this.removePermissions(DYNAMIC_PERMISSIONS.isMembersAllowedToPublishLocal);
    }
  }

  private setDataByNotifications(user?: UserUI, notifications?: Array<NotificationEventsOptionsModel>) {
    if (
      !!notifications?.find(
        notification =>
          notification.notificationEventCode === NotificationEventCodeEnum.onBirthdaysDaily &&
          notification.sendByBot === false &&
          notification.sendByEmail === false &&
          notification.sendByPush === false,
      )
    ) {
      this.addPermissions(DYNAMIC_PERMISSIONS.showBirthdaysNotificationsBanner);
    }
  }

  private getCustomName(customNames: CustomNamesItem[], customName: CustomNamesItem): CustomNamesItem {
    if (!!customName.title) {
      return customName;
    } else {
      const customNameItemOtherLanguageCode = customNames.filter(
        customNameItem => customNameItem.codeName === customName.codeName && !!customNameItem.title,
      )[0];
      return customNameItemOtherLanguageCode || customName;
    }
  }

  private addPermissions(permission: string | string[]) {
    this.ngxPermissionsService.addPermission(permission);

    const newPermissions = Object.keys(this.ngxPermissionsService.getPermissions());
    this.ngxPermissionsService.loadPermissions(newPermissions);
  }

  private removePermissions(permissions: string | string[]) {
    if (typeof permissions === 'string') {
      this.ngxPermissionsService.removePermission(permissions);
    } else {
      permissions.forEach(permission => this.removePermissions(permission));
    }

    const newPermissions = Object.keys(this.ngxPermissionsService.getPermissions());
    this.ngxPermissionsService.loadPermissions(newPermissions);
  }

  // TODO: проверка разрешения в ролевой модели (дефолтных разрешениях)
  private includePermissionInDefaultPermissions(permission: string, roles: NgxRolesObject) {
    return !!Object.keys(roles).find(role => ROLE_PERMISSIONS_DEFAULT[role as RoleCode].includes(permission));
  }
}
