<form class="user-info-edit" [formGroup]="form">
  <div class="user-info-edit__block">
    <app-photo
      class="image"
      [mode]="formDisabled ? 'view' : 'edit'"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
      (photoChange)="onPhotoChange($event)"
    ></app-photo>

    <tui-input
      tuiAutoFocus
      tabindex="2"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      formControlName="lastName"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.lastName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.lastName" />
    </tui-input>

    <tui-input
      tabindex="3"
      formControlName="firstName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.firstName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.firstName" />
    </tui-input>

    <tui-input
      tabindex="4"
      formControlName="middleName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.middleName' | translate }}
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.middleName" />
    </tui-input>

    <div class="field">
      <div tuiGroup>
        <tui-input-number
          tabindex="5"
          formControlName="birthDateDay"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [min]="1"
          [max]="31"
        >
          {{ 'components.userInfo.fields.day' | translate }}
        </tui-input-number>

        <tui-select
          tabindex="6"
          formControlName="birthDateMonth"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [valueContent]="months ? monthStringify(months) : ''"
        >
          {{ 'components.userInfo.fields.month' | translate }}
          <tui-data-list *tuiDataList>
            <button *ngFor="let month of months" tuiOption [value]="month.id">
              {{ month.name | translate }}
            </button>
          </tui-data-list>
        </tui-select>

        <tui-input-year
          tabindex="7"
          formControlName="birthDateYear"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
          [tuiTextfieldCleaner]="true"
          [min]="1900"
          [max]="2990"
        >
          {{ 'components.userInfo.fields.year' | translate }}
        </tui-input-year>
      </div>
    </div>

    <ng-container *ngIf="!(allowOnlyYourShortProfileEditing$ | async)">
      <!-- FIXME: app-input-phone -->
      <app-input-phone
        *ngIf="mode === 'create' && (allowSpecialFieldsEditing$ | async)"
        #phone
        tabindex="8"
        formControlName="phone"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [size]="tuiElementLargeSize"
        [(phoneValue)]="phone.value"
        (phoneValueChange)="onChangePhone($event)"
        [pseudoInvalid]="phone.invalid || (phone.touched && form.errors?.['atLeastOne'])"
        [forceDisabled]="mode === 'create' ? false : undefined"
      >
        {{ 'components.userInfo.fields.mobilePhone' | translate }}
      </app-input-phone>
    </ng-container>

    <tui-input
      *ngIf="allowEmailFieldEditing$ | async"
      #email
      tabindex="9"
      formControlName="email"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [pseudoInvalid]="email.touched && form.errors?.['atLeastOne']"
    >
      {{ 'components.userInfo.fields.email' | translate }}
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.email" />
    </tui-input>

    <ng-container *ngIf="!(allowOnlyYourShortProfileEditing$ | async)">
      <tui-textarea
        class="field field_height_min"
        tabindex="11"
        formControlName="resources"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [expandable]="true"
      >
        {{ 'components.userInfo.fields.resources' | translate }}
      </tui-textarea>

      <tui-input
        tabindex="12"
        formControlName="hobbies"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.userInfo.fields.hobbies' | translate }}
      </tui-input>

      <ng-container *ngIf="allowSpecialFieldsForAssociationEditing$ | async">
        <search-users-input
          *ngIf="authUser$ | async as authUser"
          mode="single"
          formControlName="curator"
          [label]="'components.userInfo.fields.curator' | translate"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [filter]="{
            searchForRegistredOnly: true,
            organisationIds: authUser.parentOrganisationId ? [authUser.parentOrganisationId] : []
          }"
          [excludeFilterFields]="['organizations']"
          [showFilterButton]="true"
          [textfieldSize]="tuiElementLargeSize"
        ></search-users-input>

        <tui-input-date
          tabindex="13"
          formControlName="authorityValidTill"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [tuiTextfieldSize]="tuiElementLargeSize"
        >
          {{ 'components.userInfo.fields.authorityValidTill' | translate }}
        </tui-input-date>

        <ng-container *ngIf="!form.value.oldPaymentDetails">
          <tui-input-files
            *ngIf="!form.value.paymentDetails"
            class="field size_{{ tuiElementLargeSize }}"
            tabindex="14"
            formControlName="paymentDetails"
            [label]="' '"
            [link]="'label.userProfileAttachment' | customNames"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
            [size]="tuiElementLargeSize"
            [maxFileSize]="maxDocFileSize"
          >
            <input tuiInputFiles accept=".pdf, .doc, .docx, .rtf" />
          </tui-input-files>
          <tui-files *ngIf="!!form.value.paymentDetails">
            <tui-file [file]="form.value.paymentDetails" (removed)="form.get('paymentDetails')?.reset()"></tui-file>
          </tui-files>
        </ng-container>
        <ng-container *ngIf="!!form.value.oldPaymentDetails">
          <tui-files>
            <tui-file
              [file]="form.value.oldPaymentDetails"
              (removed)="form.get('oldPaymentDetails')?.reset()"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
            ></tui-file>
          </tui-files>
        </ng-container>

        <tui-checkbox-block
          class="field"
          tabindex="16"
          formControlName="isFeePaid"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
          [size]="tuiElementSmallSize"
        >
          {{ 'components.userInfo.fields.feeNotPaid' | translate }}
        </tui-checkbox-block>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="allowSpecialFieldsForAssociationEditing$ | async">
    <div class="user-info-edit__organisations" formArrayName="organisations">
      <tui-island *ngFor="let organisation of organisations.controls; let oIndex = index" class="tui-island">
        <button
          *ngIf="allowIsDefaultOrganisationEditing$ | async"
          tuiIconButton
          type="button"
          appearance="icon"
          size="xs"
          [disabled]="isDefaultOrganisation(oIndex)"
          (click)="isDefaultOrganisation(oIndex) ? undefined : setDefaultOrganisation(oIndex)"
        >
          <tui-svg
            class="tui-island__icon"
            [class.tui-island__icon_isDefault]="isDefaultOrganisation(oIndex)"
            [src]="'tuiIconStar'"
          ></tui-svg>
        </button>

        <span class="tui-island__title">{{ organisation.get('organisationName')?.value }}</span>
        <div class="tui-island__paragraph item" [formGroupName]="oIndex">
          <ng-container
            *ngIf="
              (allowJobTitleFieldsEditing$ | async) &&
              ((allowAssociationEmployeeEditing$ | async) ||
                ((allowOrganisationEmployeeEditing$ | async) &&
                  organisation.get('organisationId')?.value === organisationId) ||
                authUser$.value?.id === data?.id)
            "
          >
            <tui-input
              class="field"
              tabindex="17"
              formControlName="jobTitlesDescription"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [tuiTextfieldSize]="tuiElementLargeSize"
            >
              {{ 'components.userInfo.fields.jobTitlesDescription' | translate }}
            </tui-input>

            <tui-multi-select
              *tuiLet="jobsForEditing$ | async as jobs"
              class="field"
              tabindex="18"
              formControlName="jobTitlesIds"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [tuiTextfieldCleaner]="true"
              [expandable]="true"
              [editable]="false"
              [stringify]="stringifyJobTitle"
            >
              {{ 'field.jobTitle' | customNames }}
              <span class="tui-required"></span>
              <tui-data-list *tuiDataList tuiMultiSelectGroup>
                <button *ngFor="let job of jobs" tuiOption [value]="job.jobTitleId">
                  {{ job.jobTitleName }}
                </button>
              </tui-data-list>
            </tui-multi-select>
          </ng-container>

          <div *ngIf="(allowSpecialFieldsEditing$ | async) && (allowAssociationEmployeeEditing$ | async)">
            <tui-select
              *tuiLet="
                (organisation.get('organisationId')?.value === currentParentOrganisationId || isParentOrganisation
                  ? rolesForAssociation$
                  : rolesForOrganisation$
                ) | async as roles
              "
              class="field"
              tabindex="19"
              formControlName="roleIds"
              [class.tui-skeleton]="loading || !roles?.length"
              [class.tui-skeleton_text]="loading || !roles?.length"
              [valueContent]="roles ? rolesStringify(roles) : ''"
              [tuiTextfieldSize]="tuiElementLargeSize"
            >
              {{ 'components.userInfo.fields.role' | translate }}
              <span class="tui-required"></span>
              <tui-data-list *tuiDataList>
                <button *ngFor="let role of roles" tuiOption [value]="[role.id]">
                  {{ role.description }}
                </button>
              </tui-data-list>
            </tui-select>
          </div>
        </div>
      </tui-island>
    </div>

    <app-contacts
      class="user-info-edit__contacts"
      formControlName="contacts"
      [allowCreating]="allowContactFieldCreating$ | async"
      [allowEditing]="allowContactFieldEditing$ | async"
      [allowDeleting]="allowContactFieldDeleting$ | async"
      [loading]="loading"
    ></app-contacts>
  </ng-container>

  <div
    *ngIf="(authUser$ | async)?.id === data?.id"
    class="user-info-edit__delete-link"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_text]="loading"
  >
    <app-delete-auth-user-link [(processing)]="loading"></app-delete-auth-user-link>
  </div>

  <div *ngIf="!loading" class="user-info-edit__buttons">
    <button
      tuiButton
      class="user-info-edit-button"
      tabindex="26"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>

    <button
      tuiButton
      class="user-info-edit-button"
      tabindex="27"
      type="button"
      appearance="secondary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>
