import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { from, Observable } from 'rxjs';
import { CommitteeView } from '@src/api';
import { ViewMode } from '@src/models';
import { CommitteeService } from '@src/core/services';
import { TuiSizeS, TuiSizeM, TuiSizeL } from '@taiga-ui/core';

@Component({
  selector: 'field-new-committee',
  templateUrl: './field-new-committee.component.html',
  styleUrls: ['./field-new-committee.component.scss', '../styles/field-new-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNewCommitteeComponent {
  @Input() mode?: ViewMode = 'view';
  @Input() name?: string;
  @Input() size: TuiSizeS | TuiSizeM | TuiSizeL = 's';
  @Input() set committeeId(value: string | undefined | null) {
    this.committee$ = undefined;
    if (value) {
      this.committee$ = from(this.committeeService.getCommitteeAsync(value));
    }
  }

  committee$?: Observable<CommitteeView>;

  constructor(private committeeService: CommitteeService) {}
}
