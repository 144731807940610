<div class="download-links">
  <img [src]="downloadOptions.logoSrc" alt="Logo" class="download-links__logo" />

  <div class="download-links__title">{{ downloadOptions.title ?? '' | translate }}</div>
  <div class="download-links__subtitle">
    {{ downloadOptions.description ?? '' | translate }}
  </div>

  <a tuiButton class="download-links__button" appearance="outline" size="m" target="_blank" [href]="getDownloadLink()">
    {{ env.isWebAndroid ? ('components.downloadLinks.buttons.downloadInGooglePlay' | translate) : '' }}
    {{ env.isWebIos ? ('components.downloadLinks.buttons.downloadInAppStore' | translate) : '' }}
  </a>
</div>
