import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  LoyaltyCodesForProductService,
  LoyaltyCodesForProductView,
  LoyaltyFavoriteProductService,
  LoyaltyService,
  ViewLoyaltyProduct,
} from '@src/api';

import { ALL_REGIONS_ID } from '../constants';
import { LoyaltyProgramFilterData } from '../types';

import { DEFAULT_ITEMS_WITH_PAGING, ITEMS_PER_PAGE_BY_DEFAULT, ITEMS_PER_PAGE_BY_NEW } from './constants';
import { convertToProduct } from './utils/convertToProduct';
import { LoyaltyProgramProductWithPagingResult } from './types';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramReadService {
  readonly products$ = new BehaviorSubject<LoyaltyProgramProductWithPagingResult>({ ...DEFAULT_ITEMS_WITH_PAGING });
  readonly ownProducts$ = new BehaviorSubject<LoyaltyProgramProductWithPagingResult>({ ...DEFAULT_ITEMS_WITH_PAGING });
  readonly favoriteProducts$ = new BehaviorSubject<LoyaltyProgramProductWithPagingResult>({
    ...DEFAULT_ITEMS_WITH_PAGING,
  });
  readonly myProductCodes$ = new BehaviorSubject<LoyaltyCodesForProductView[] | null>(null);
  readonly bestOffers$ = new BehaviorSubject<LoyaltyProgramProductWithPagingResult>({ ...DEFAULT_ITEMS_WITH_PAGING });

  private ownProductsStart = 0;
  private productsByFilterStart = 0;
  private bestOffersStart = 0;

  private ownProductsHasMore = false;
  private productsByFilterHasMore = false;
  private bestOfferHasMore = false;

  constructor(
    private readonly api: LoyaltyService,
    private readonly loyaltyFavoriteProductService: LoyaltyFavoriteProductService,
    private readonly loyaltyCodesForProductService: LoyaltyCodesForProductService,
  ) {}

  getProductsByFilter(filter: LoyaltyProgramFilterData, query: string, nextPage = false) {
    if (nextPage && !this.productsByFilterHasMore) {
      return;
    }

    const { categories = [], region, sortByDatePublished } = filter ?? {};

    if (nextPage) {
      this.productsByFilterStart += ITEMS_PER_PAGE_BY_DEFAULT;
    } else {
      this.productsByFilterStart = 0;
      this.productsByFilterHasMore = false;
    }

    this.api
      .searchForLoyaltyProduct({
        name: query?.trim() ? query?.trim() : undefined,
        categoryIds: categories?.length ? categories.map(item => item.id) : undefined,
        regionIds: region?.id && region.id !== ALL_REGIONS_ID ? [region.id] : undefined,
        sortByDatePublished,
        start: this.productsByFilterStart,
        top: sortByDatePublished ? ITEMS_PER_PAGE_BY_NEW : ITEMS_PER_PAGE_BY_DEFAULT,
      })
      .subscribe(result => {
        this.productsByFilterHasMore = this.preparePagingResult(this.products$, nextPage, result);
      });
  }

  getBestOffers(nextPage = false) {
    if (nextPage && !this.bestOfferHasMore) {
      return;
    }

    if (nextPage) {
      this.bestOffersStart += ITEMS_PER_PAGE_BY_DEFAULT;
    } else {
      this.bestOffersStart = 0;
      this.bestOfferHasMore = false;
    }

    this.api.bestProducts(this.bestOffersStart, ITEMS_PER_PAGE_BY_DEFAULT).subscribe(result => {
      this.bestOfferHasMore = this.preparePagingResult(this.bestOffers$, nextPage, result);
    });
  }

  getOwnProducts(nextPage = false) {
    if (nextPage && !this.ownProductsHasMore) {
      return;
    }

    if (nextPage) {
      this.ownProductsStart += ITEMS_PER_PAGE_BY_DEFAULT;
    } else {
      this.ownProductsStart = 0;
      this.ownProductsHasMore = false;
    }

    this.api.myProducts(this.ownProductsStart, ITEMS_PER_PAGE_BY_DEFAULT).subscribe(result => {
      this.ownProductsHasMore = this.preparePagingResult(this.ownProducts$, nextPage, result);
    });
  }

  getFavoriteProducts() {
    this.loyaltyFavoriteProductService.apiLoyaltyProductFavoritesListGet().subscribe(result => {
      this.preparePagingResult(this.favoriteProducts$, false, result);
    });
  }

  getMyProductCodes() {
    this.loyaltyCodesForProductService.myProductCodes().subscribe(result => {
      this.myProductCodes$.next(result);
    });
  }

  private preparePagingResult(
    stream$: BehaviorSubject<LoyaltyProgramProductWithPagingResult>,
    nextPage: boolean,
    next: ViewLoyaltyProduct[],
  ): boolean {
    const items = next.map(item => convertToProduct(item));
    const hasMore = items.length === ITEMS_PER_PAGE_BY_DEFAULT;

    if (nextPage) {
      // TODO: неясно как правильно организовать получение предыдущего значения
      stream$.next({ items: [...(stream$.value.items ?? []), ...items], hasMore });
    } else {
      stream$.next({ items, hasMore });
    }

    return hasMore;
  }

  get isOwnProductsHasMore() {
    return this.ownProductsHasMore;
  }

  get isProductsByFilterHasMore() {
    return this.productsByFilterHasMore;
  }

  get isBestOfferHasMore() {
    return this.bestOfferHasMore;
  }
}
