import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ListDisplayMode } from '@src/app/modules/event/event-list';

/**
 * Компонент переключения вида списка
 * @param {ListDisplay} display - Вид списка (по умолчанию list)
 */
@Component({
  selector: 'app-display-switcher',
  templateUrl: './display-switcher.component.html',
  styleUrls: ['./display-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplaySwitcherComponent {
  @Input() display: ListDisplayMode = 'list';
  @Output() displayChange: EventEmitter<ListDisplayMode> = new EventEmitter();
}
