import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { EnvService } from '@src/app/modules/env';

import { DEFAULT_DOWNLOAD_LINKS } from './constants';

@Component({
  selector: 'app-download-links',
  templateUrl: './download-links.component.html',
  styleUrls: ['./download-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadLinksComponent {
  @Input() options? = DEFAULT_DOWNLOAD_LINKS;

  constructor(public readonly env: EnvService) {}

  get downloadOptions() {
    return this.options ?? DEFAULT_DOWNLOAD_LINKS;
  }

  getDownloadLink() {
    if (this.env.isWebIos) {
      return this.downloadOptions.iosDownloadLink;
    }

    if (this.env.isWebAndroid) {
      return this.downloadOptions.androidDownloadLink;
    }

    return '';
  }
}
