import { EventWithParticipantsAdd, EventWithParticipantsEdit, EventViewWithParticipants } from '../api';

import { ChatModel } from './chat.model';

export interface EventViewUI extends EventViewWithParticipants {
  active?: boolean;
}

export interface EventViewGroupUI {
  events: EventViewUI[];
  month: number;
  year: number;
}

export type Relevance = 'archive' | 'current';

export interface EventUI extends EventWithParticipantsAdd, EventWithParticipantsEdit, EventViewWithParticipants {
  photo?: File;
  photoPath?: string;
  photoId?: string;
  program?: File;
  docs?: File[];
  createGroupChat?: boolean;
  fillOnlineLink?: boolean;
  groupChat?: ChatModel;
  contactPersonFullName?: string;
}
