import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TuiSizeS } from '@taiga-ui/core';

import { MoreMenuItem } from './types';

/**
 * Кнопка, при нажатии на которую отображается дополнительное меню
 * @param {MoreMenuItem[]} items {@link MoreMenuItem} - Дополнительное меню
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=1531-47654&mode=dev
 */
@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreButtonComponent implements OnChanges {
  @Input() items: MoreMenuItem[] = [];
  @Input() size: TuiSizeS = 's';

  visibleItems: MoreMenuItem[] = [];
  open: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.visibleItems = this.items.filter(item => item.visible !== false);
    }
  }

  onClickButton(event: MouseEvent) {
    this.open = !this.open;
    event.stopPropagation();
  }

  onClickItem(item: MoreMenuItem) {
    item.action();
    this.open = false;
  }
}
