<tui-avatar
  class="image"
  [class.tui-skeleton]="loading"
  [class.tui-skeleton_rounded]="loading"
  [size]="size"
  [ngStyle]="{ width, height, 'border-radius': borderRadius, cursor: photoId || photoSrc ? 'pointer' : 'default' }"
  [autoColor]="!photoId && !photoSrc"
  [rounded]="rounded"
  [avatarUrl]="photoSrc || (photoId | getImageSrc : '' : 'thumbnail' : unionId) | safe : 'resourceUrl'"
  [text]="text || ''"
  (click)="onClick()"
></tui-avatar>
