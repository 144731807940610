import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { BreakpointObserverHelperService, JobTitleService, RoleService, UserService } from '@src/core/services';
import { CommitteeUI, JobTitleUI, UserUI, OrganisationRolesJobTitlesFormData, ContactUI } from '@src/models';
import { OrganisationRolesJobTitles, RoleView } from '@src/api';
import { DateToTuiDateTimePipe } from '@src/app/shared/pipes';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { ObjectId } from '@src/types/id';
import { Language } from '@src/core';
import { ReportsService } from '@src/app/modules/reports';
import { EnvService } from '@src/app/modules/env';

import { PermissionService } from '../services';
import { ARRAY_YEARS_FOR_HIDE, DEFAULT_YEAR_FOR_HIDE } from '../constants';

import { FormData, FormDataControls } from './types';

@Component({
  selector: 'app-user-info-view',
  templateUrl: './user-info-view.component.html',
  styleUrls: ['./user-info-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoViewComponent extends ResizableBaseComponent implements OnChanges {
  @Input() data: UserUI | null = {};
  @Input() organisationId?: string | null;
  @Input() committeesList?: CommitteeUI[] | null;
  @Input() allowDeleting?: boolean | null = false;
  @Output() startEditing = new EventEmitter<void>();
  @Output() deleted = new EventEmitter<UserUI>();
  @Input() externalLoading: boolean = true;

  readonly allowDeleting$ = this.permService.allowDeleting$;
  readonly allowEditing$ = this.permService.allowEditing$;
  readonly allowPhoneFieldViewing$ = this.permService.allowPhoneFieldViewing$;
  readonly allowRoleFieldViewing$ = this.permService.allowRoleFieldViewing$;
  readonly allowEmailFieldViewing$ = this.permService.allowEmailFieldViewing$;
  readonly allowSpecialFieldsForAssociationViewing$ = this.permService.allowSpecialFieldsForAssociationViewing$;
  readonly allowOnlyYourShortProfileViewing$ = this.permService.allowOnlyYourShortProfileViewing$;

  innerLoading = true;
  jobsForViewing$ = this.jobTitleService.jobTitles$;
  roles$ = this.roleService.roles$;
  form!: UntypedFormGroup;
  authUser$ = this.userService.authUser$;
  reportsButtonVisible = false;

  get loading() {
    return this.externalLoading || this.innerLoading;
  }

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly permService: PermissionService,
    private readonly datePipe: DatePipe,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly jobTitleService: JobTitleService,
    private readonly roleService: RoleService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dateToTuiDateTimePipe: DateToTuiDateTimePipe,
    private readonly reportsService: ReportsService,
    private readonly env: EnvService,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get organisations(): UntypedFormArray {
    return this.form.get('organisations') as UntypedFormArray;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.data) {
      this.innerLoading = true;

      this.form.reset();
      this.organisations.reset();

      if (changes.data.currentValue) {
        this.initForm();

        const mappedData = await this.mapUserUiToFormData(changes.data.currentValue);
        this.form.patchValue(mappedData);
      } else {
        this.form.patchValue({
          organisationId: this.organisationId ?? undefined,
        });
      }

      this.innerLoading = false;
    }

    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.jobTitleService.getJobTitles();
    this.roleService.getRoles();

    this.reportsService
      .getReports('users')
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(reports => {
        this.reportsButtonVisible = !!reports.length;
        this.cdr.markForCheck();
      });
  }

  onClickChatButton(): void {
    if (!this.data) return;

    this.userService.openChatWithUser(this.data);
  }

  onClickEditButton(): void {
    this.startEditing.emit();
  }

  onClickDeleteButton(): void {
    if (!this.data || !this.form.value) return;

    const mappedData = this.mapFormDataToUserUI(this.form.value);
    this.deleted.emit(mappedData);
  }

  showReportsDialog(content: PolymorpheusContent<TuiDialogContext>) {
    this.dialogs.open(content).subscribe();
  }

  onSelectedCommitteeIdChange(committeeId?: ObjectId) {
    if (!committeeId) {
      return;
    }

    return this.router.navigate(['committees', committeeId]);
  }

  readonly stringifyJobTitle: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return this.jobsForViewing$.value.find(jobs => jobs.jobTitleId === item)?.jobTitleName ?? '';
  };

  @tuiPure
  rolesStringify(items: ReadonlyArray<RoleView>): TuiStringHandler<TuiContextWithImplicit<string[]>> {
    const map = new Map(items.map(({ id, description }) => [id, description] as [string, string]));
    return ({ $implicit }: TuiContextWithImplicit<string[]>) => map.get($implicit[0]) || '';
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(<FormDataControls>{
      id: new UntypedFormControl(undefined),
      firstName: new UntypedFormControl(undefined, Validators.required),
      middleName: new UntypedFormControl(undefined),
      lastName: new UntypedFormControl(undefined, Validators.required),
      phone: new UntypedFormControl(undefined, Validators.required),
      email: new UntypedFormControl(undefined, Validators.required),
      defaultJobTitlesIds: new UntypedFormControl(undefined),
      telegramId: new UntypedFormControl(undefined),
      jobTitles: new UntypedFormControl(undefined, Validators.required),
      jobDescription: new UntypedFormControl(undefined),
      roleId: new UntypedFormControl(undefined, Validators.required),
      organisationId: new UntypedFormControl(undefined),
      organisations: this.formBuilder.array([]),
      birthDate: new UntypedFormControl(undefined),
      birthDateDay: new UntypedFormControl(undefined),
      birthDateMonth: new UntypedFormControl(undefined),
      birthDateYear: new UntypedFormControl(undefined),

      resources: new UntypedFormControl(undefined),
      hobbies: new UntypedFormControl(undefined),
      isFeePaid: new UntypedFormControl(false),
      paymentDetailsId: new UntypedFormControl(undefined),
      authorityValidTill: new UntypedFormControl(undefined),
      curatorId: new UntypedFormControl(undefined),

      contacts: new UntypedFormControl(undefined),
    });
  }

  private async mapUserUiToFormData(user: UserUI) {
    const {
      id,
      firstName,
      lastName,
      middleName,
      email,
      telegramId,
      resources,
      hobbies,
      isFeePaid,
      paymentDetailsId,
      curatorId,
      organisationJobTitles,
    } = user;

    let phone: string | undefined;
    let organisations: OrganisationRolesJobTitlesFormData[] = [];
    let defaultJobTitlesIds: string[] = [];

    if (this.allowPhoneFieldViewing$.value) {
      phone = user.phone;
    }

    if (user.organisations) {
      organisations = await Promise.all(
        user.organisations.map(async organisation => {
          this.initOrganisation();

          const organisationJobTitle = organisationJobTitles?.find(
            organisationJobTitle => organisationJobTitle.organisationId === organisation.organisationId,
          );
          const organisationName = organisationJobTitle?.organisationName;

          let roleIds: string[] | undefined;
          let jobTitles: JobTitleUI[] = [];

          if (this.allowRoleFieldViewing$.value && organisation?.organisationId && user?.id) {
            roleIds = [(await this.roleService.getUserRolesData(user.id, organisation.organisationId))[0]?.id ?? ''];
          }

          jobTitles = this.jobsForViewing$.value.filter(
            job =>
              organisationJobTitles &&
              organisationJobTitles.findIndex(
                organisationJobTitle => organisationJobTitle.jobTitleId === job.jobTitleId,
              ) > -1,
          );

          const jobTitlesIds: string[] =
            organisation?.jobTitles?.filter(jobTitle => Boolean(jobTitle.id)).map(jobTitle => jobTitle.id!) ?? [];
          const jobTitlesDescription = organisation?.jobTitles?.[0].description ?? '';

          const isDefaultOrganisation = Boolean(organisation?.isDefault);
          if (isDefaultOrganisation) {
            defaultJobTitlesIds = jobTitlesIds;
          }

          return <OrganisationRolesJobTitlesFormData>{
            organisationId: organisation?.organisationId,
            organisationName,
            jobTitles,
            jobTitlesIds,
            jobTitlesDescription,
            roleIds,
            isDefault: isDefaultOrganisation,
          };
        }),
      );
    }

    const birthDate = user.birthDate ? new Date(user.birthDate) : undefined;
    const birthDateYear =
      birthDate && !ARRAY_YEARS_FOR_HIDE.includes(birthDate.getFullYear()) ? birthDate?.getFullYear() : undefined;
    const birthDateString = birthDate
      ? birthDateYear
        ? this.datePipe.transform(birthDate, 'dd MMMM y')
        : this.datePipe.transform(birthDate, 'dd MMMM')
      : undefined;

    const authorityValidTill = this.dateToTuiDateTimePipe.transform(user.authorityValidTill, 'date');

    const contacts: ContactUI[] = id ? await this.userService.getUserContactsData(id) : [];

    return <FormData>{
      id,
      firstName,
      middleName,
      lastName,
      phone,
      email,
      defaultJobTitlesIds,
      telegramId,
      birthDate: birthDateString,
      birthDateDay: birthDate?.getDate(),
      birthDateMonth: birthDate ? birthDate.getMonth() + 1 : undefined,
      birthDateYear,

      organisations,
      resources,
      hobbies,
      isFeePaid,
      paymentDetailsId,
      authorityValidTill,
      curatorId,

      contacts,
    };
  }

  private mapFormDataToUserUI(formData: FormData) {
    const {
      id,
      firstName,
      middleName,
      lastName,
      email,
      phone,
      telegramId,
      jobDescription,
      birthDateDay,
      resources,
      hobbies,
      isFeePaid,
      curatorId,
    } = formData;

    const birthDateMonth = formData.birthDateMonth;
    const birthDateYear = formData.birthDateYear ?? DEFAULT_YEAR_FOR_HIDE;
    const birthDate =
      birthDateDay && birthDateMonth ? new Date(birthDateYear, birthDateMonth - 1, birthDateDay, 0, 0, 0) : null;

    const authorityValidTill = formData.authorityValidTill
      ? new Date(formData.authorityValidTill.year, formData.authorityValidTill.month, formData.authorityValidTill.day)
      : undefined;

    const organisations = formData.organisations.map(organisation => {
      return <OrganisationRolesJobTitles>{
        organisationId: organisation.organisationId,
        isDefault: organisation.isDefault,
        jobTitles: organisation.jobTitlesIds?.map(jobTitleId => {
          return {
            id: jobTitleId,
            description: organisation.jobTitlesDescription,
          };
        }),
        roleIds: organisation.roleIds,
      };
    });

    if (organisations.length > 1) {
      const indexToDelete = organisations.findIndex(
        organisation => organisation.isDefault && organisation.organisationId === this.organisationId,
      );

      if (indexToDelete > -1) {
        organisations[indexToDelete].isDefault = false;

        if (organisations.length > indexToDelete + 1) {
          organisations[indexToDelete + 1].isDefault = true;
        } else {
          organisations[indexToDelete - 1].isDefault = true;
        }
      }
    }

    return <UserUI>{
      firstName,
      middleName,
      lastName,
      email,
      phone,
      telegramId,
      jobTitleDescription: jobDescription,
      birthDate: birthDate ? formatDate(birthDate, 'yyyy-MM-dd', Language.EN) : undefined,
      resources,
      hobbies,
      isFeePaid: isFeePaid !== null ? isFeePaid : undefined,
      curatorId,
      authorityValidTill,
      organisations,
      organisationsToDelete: [this.organisationId],
      id,
    };
  }

  private async initOrganisation() {
    this.organisations.push(
      this.formBuilder.group({
        organisationId: [undefined],
        organisationName: [undefined],
        jobTitles: [[]],
        jobTitlesIds: [[]],
        jobTitlesDescription: [undefined],
        roleIds: [undefined],
        isDefault: [undefined],
      }),
    );
  }
}
