import { Component, ChangeDetectionStrategy, Input, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { EnvService } from '@src/app/modules/env';
import { Nullable, Optional } from '@src/types/utils';
import { TuiDialogContext, TuiDialogService, TuiHorizontalDirection } from '@taiga-ui/core';
import { UrlPipe } from '@src/app/shared/pipes';
import { RouteDefinition, getInnerRouteDefinitionFromLink, isEmailUrl, isPhoneUrl, isValidUrl } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { Observer } from 'rxjs';
import { Router } from '@angular/router';

import { LinkColor, UrlType } from './types';

/**
 * Компонент для отображения ссылки
 * @param {string} url - ссылка
 * @param {string} displayText - текст, отображаемый вместо ссылки
 * @param {string} icon - название иконки из TaigaUI
 * @param {TuiHorizontalDirection} iconAlign - положение иконки относительно текста
 * @param {boolean} pseudo - признак отображения нижнего пунктирного подчёркивания (по-умолчанию false)
 * @param {'negative' | 'positive' | null} mode - визуальное представление режима ссылки
 */

// TODO: принимать ссылку без домена. Пример: /association?menu=users
@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() set url(value: Optional<Nullable<string>>) {
    this.importValue = value;
    this.normalizedUrl = this.urlPipe.transform(value) ?? '';
    this.routeDefinition = getInnerRouteDefinitionFromLink(value ?? '');
  }
  @Input() displayText: Optional<Nullable<string>> = null;

  @Input() icon = '';
  @Input() iconAlign: TuiHorizontalDirection = 'right';
  @Input() pseudo = false;
  @Input() color: LinkColor = 'blue';

  @Output() externalLink: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('tmpRedirectToUrl') redirectToUrlContent?: PolymorpheusContent<TuiDialogContext>;

  normalizedUrl = '';
  importValue?: Nullable<string>;

  private routeDefinition: RouteDefinition | null = null;

  constructor(
    private readonly env: EnvService,
    private readonly urlPipe: UrlPipe,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {}

  get innerLink() {
    return this.routeDefinition?.innerLink;
  }

  get urlType(): UrlType {
    return this.routeDefinition === null ? 'external' : 'internal';
  }

  get normalizedDisplayableText(): string {
    if (typeof this.displayText === 'string') {
      return this.displayText;
    }

    if (this.routeDefinition?.title) {
      return this.translateService.instant(this.routeDefinition.title);
    }

    const validUrl = isValidUrl(this.normalizedUrl);

    if (validUrl) {
      const displayableUrl = new URL(validUrl);
      return displayableUrl.origin + displayableUrl.pathname;
    }

    return this.importValue ?? '';
  }

  openLink() {
    if (!this.normalizedUrl) return;

    if (!this.env.isBot && !isPhoneUrl(this.normalizedUrl) && !isEmailUrl(this.normalizedUrl)) {
      // Под ботом не обрабатываем нажатие по ссылке, т.к. Telegram сам спрашивает подтверждение перехода
      this.showPrompt();
      return;
    }

    this.env.openLink(this.normalizedUrl);
  }

  gotoInnerLink() {
    if (!this.routeDefinition?.innerLink) return;

    this.router.navigateByUrl(this.routeDefinition.innerLink);
  }

  gotoExternalLink(observer: Observer<void>) {
    observer.complete();
    this.externalLink.emit(this.normalizedUrl);

    setTimeout(() => {
      this.env.openLink(this.normalizedUrl);
    }, 300);
  }

  private showPrompt() {
    if (!this.redirectToUrlContent) {
      return;
    }

    this.dialogService
      .open(this.redirectToUrlContent, {
        closeable: false,
        dismissible: true,
      })
      .subscribe();
  }
}
