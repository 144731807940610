import { formatDate } from '@angular/common';
import { Language } from '@src/core';

/**
 * Утилита, которая преобразует дату в строку API формата.
 *
 * @param date дата
 */
export const convertDateToApiFormat = (date?: Date | null, useTimeZone: boolean = true): string | undefined => {
  const format = useTimeZone ? 'yyyy-MM-ddTHH:mm:ss.SSSZ' : 'yyyy-MM-ddTHH:mm:ss';
  return date ? formatDate(date, format, Language.EN) : undefined;
};
