import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TUI_SANITIZER, TuiSvgModule } from '@taiga-ui/core';
import { TuiAvatarModule } from '@taiga-ui/kit';
import {
  AlertCircle,
  BarChart2,
  BellRing,
  Book,
  Building,
  Building2,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Copy,
  Copyright,
  Edit,
  Edit2,
  Filter,
  Folders,
  Forward,
  Hash,
  HelpCircle,
  Home,
  Landmark,
  LayoutDashboard,
  LogOut,
  LucideAngularModule,
  Mail,
  MapPin,
  Menu,
  MessageCircle,
  Network,
  Pin,
  PinOff,
  PlusCircle,
  Radio,
  RefreshCw,
  Reply,
  Send,
  Settings,
  ShieldCheck,
  SortAsc,
  SortDesc,
  Table,
  Trash,
  Upload,
  User,
  Users,
  X,
  Languages,
  Sun,
  CheckCheck,
  SquareStack,
  Cake,
  FilePenLine,
  CircleCheck,
  File,
  UserCog,
} from 'lucide-angular';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiAvatarModule,
    LucideAngularModule.pick({
      AlertCircle,
      BellRing,
      BarChart2,
      Building,
      Building2,
      Calendar,
      ChevronLeft,
      ChevronRight,
      Copy,
      Copyright,
      Edit,
      Edit2,
      Filter,
      Folders,
      Forward,
      Hash,
      HelpCircle,
      Home,
      Landmark,
      LayoutDashboard,
      LogOut,
      Mail,
      MapPin,
      Menu,
      MessageCircle,
      Network,
      Pin,
      PinOff,
      PlusCircle,
      Radio,
      RefreshCw,
      Reply,
      Send,
      Settings,
      ShieldCheck,
      SortAsc,
      SortDesc,
      Trash,
      User,
      Users,
      X,
      Table,
      Book,
      Upload,
      Languages,
      Sun,
      CheckCheck,
      SquareStack,
      Cake,
      FilePenLine,
      CircleCheck,
      File,
      UserCog,
    }),
  ],
  providers: [{ provide: TUI_SANITIZER, useClass: NgDompurifySanitizer }],
  exports: [IconComponent, LucideAngularModule],
})
export class IconModule {}
