import { Relevance } from '@src/app/modules/event/types';

export const getCurrentAccordionItemState = <Entity extends { id?: string }>(
  current: Entity[],
  archive: Entity[],
  selectedId?: string,
): Relevance => {
  if (selectedId && current.find(item => item.id === selectedId)) {
    return 'current';
  }

  if (selectedId && archive.find(item => item.id === selectedId)) {
    return 'archive';
  }

  if (current.length > 0) {
    return 'current';
  }

  if (archive.length > 0) {
    return 'archive';
  }

  return 'current';
};
